@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  background-color: #F5F5F5
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.image-gallery, .image-gallery-content, .image-gallery-slide-wrapper {
  height: 100%;
}
.image-gallery-content img {
  height: 190px;
}
.image-gallery-content.fullscreen img {
  height: 100vh;
}